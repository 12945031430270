<template>
  <div class="department-info">
    <!-- <div class="info-item">
      <div class="label">部门编号：</div>
      <div class="value">{{ info.orgCode }}</div>
    </div> -->
    <div class="info-item">
      <div class="label">部门类型：</div>
      <div class="value">{{ orgStruct.name || "--" }}</div>
    </div>
    <div class="info-item">
      <div class="label">负责人：</div>
      <div class="value">
        {{ additionalInfo.principal || "--" }}
      </div>
    </div>
    <div class="info-item">
      <div class="label">手机号码：</div>
      <div class="value">
        {{ additionalInfo.phone || "--" }}
      </div>
    </div>
    <div class="info-item">
      <div class="label">所在省份：</div>
      <div class="value">{{ additionalInfo.provinceName || "--" }}</div>
    </div>
    <div class="info-item">
      <div class="label">所在市：</div>
      <div class="value">
        {{ additionalInfo.cityName || "--" }}
      </div>
    </div>
    <div class="info-item">
      <div class="label">地址：</div>
      <div class="value">{{ additionalInfo.location || "--" }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepartmentInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    additionalInfo() {
      return this.info.additionalInfo || {};
    },
    orgStruct() {
      return this.info.orgStruct || {};
    },
  },
};
</script>
<style lang="scss" scoped>
.department-info {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: flow;
  flex-direction: row;
  .info-item {
    margin-top: 20px;
    flex: 0 0 33.33%;
    display: flex;
    .label {
      width: 70px;
      // margin-right: 10px;
      font-size: 14px;
      color: #666666;
      line-height: 21px;
    }
    .value {
      font-size: 14px;
      color: #333333;
      line-height: 21px;
    }
  }
}
</style>
