/* 待办：3个方法传入参数：1、详情弹窗 2、修改员工信息关键字带入 3、修改账户
4、什么情况下可修改员工密码 */
<template>
  <div class="staff-item" :class="staffItem.enable ? 'onDuty' : 'resign'">
    <div class="staff-mask">
      <div class="mask-top">
        <el-button @click="checkStaffDetail">查看详情</el-button>
      </div>
      <div
        class="mask-bottom"
        v-has="['utos:tosm:staff:update', 'utos:tosm:staff:account:update']"
      >
        <el-button @click="updateStaff" v-has="'utos:tosm:staff:update'"
          >修改信息</el-button
        >
        <div class="split-line"></div>
        <el-button
          @click="updateAccount"
          v-has="'utos:tosm:staff:account:update'"
          >修改账户</el-button
        >
      </div>
    </div>
    <div class="top">
      <svg class="icon avatar" aria-hidden="true">
        <use
          :xlink:href="`
              #icon-touxiang-${additionalInfo.gender === 1 ? 'nv' : 'nan'}
            `"
        ></use>
      </svg>
      <div class="top-right">
        <div class="staff-name ellipsis">{{ staffItem.name }}</div>
        <div class="staff-no ellipsis" v-if="additionalInfo.staffNo">
          {{ additionalInfo.staffNo || "--" }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-block bottom-block_left ellipsis">
        <i class="icon iconfont icon-zhiwei"></i>
        {{ roleName || "--" }}
      </div>
      <div class="bottom-block bottom-block_right ellipsis">
        <i class="icon iconfont icon-zhuangtai" />
        {{ staffItem.enable ? "在职" : "离职" }}
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetStaffDetail } from "@/api/organizeStaff";
export default {
  name: "StaffItem",
  props: {
    staffItem: {},
  },
  data() {
    return {};
  },
  computed: {
    additionalInfo() {
      return this.staffItem.additionalInfo || {};
    },
    roleName() {
      const roles = this.staffItem.roles || [];
      return roles.length > 0 ? roles[0].name : "";
    },
  },
  methods: {
    checkStaffDetail() {
      apiGetStaffDetail(this.staffItem.id).then((res) => {
        this.$emit("modify-staff", {
          action: "viewDetail",
          data: res,
        });
      });
    },
    updateStaff() {
      apiGetStaffDetail(this.staffItem.id).then((res) => {
        this.$emit("modify-staff", {
          action: "edit",
          data: res,
        });
      });
    },
    updateAccount() {
      this.$emit("update-account", this.staffItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.staff-item {
  position: relative;
  flex: 0 0 297px;
  height: 151px;
  margin: 0 25px 24px 0;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;

  &.onDuty {
    background: url("./onDuty.png") no-repeat;
    background-size: cover;
  }
  &.resign {
    background: url("./resigned.png") no-repeat;
    background-size: cover;
    .top .top-right {
      margin-left: 24px;
      color: #7e7f7e;
    }
    .bottom {
      color: #7e7f7e;
      .bottom-block.bottom-block_left {
        border-right: 1px solid #7e7f7e;
      }
    }
  }
  &:hover .staff-mask {
    // display: block;
    opacity: 1;
  }
  .staff-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity ease-in 0.2s;
    // display: none;
    opacity: 0;
    ::v-deep .el-button {
      width: 100%;
      height: 50px;
      background: transparent;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      border: none;
    }
    .mask-top {
      border-radius: 8px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.55);
    }
    .mask-bottom {
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.55);
      .split-line {
        flex: 0 0 1px;
        width: 1px;
        height: 14px;
        background: #ffffff;
      }
      ::v-deep .el-button {
        flex: 1;
        &:last-child::before {
        }
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    margin: 24px 0 37px 24px;
    .avatar {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .top-right {
      margin-left: 24px;
      color: #3c372b;
      flex: 1;
      max-width: calc(100% - 98px);

      .staff-name {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      .staff-no {
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #3c372b;
    line-height: 16px;
    .icon {
      margin-right: 10px;
      width: 16px;
      height: 16px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .bottom-block {
      padding: 0 12px;
      box-sizing: border-box;
      flex: 0 0 50%;
      text-align: center;

      &.bottom-block_left {
        border-right: 1px solid #3c372b;
      }
    }
  }
}
</style>
