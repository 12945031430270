<template>
  <div class="page">
    <gc-header header-data="staffManage">
      <template #ops>
        <el-button
          type="primary"
          @click="modifyStaff({ action: 'add' })"
          v-has="'utos:tosm:staff:insert'"
          >新增员工</el-button
        >
      </template>
    </gc-header>
    <div class="page-main">
      <div class="page-main-left" v-if="orgEnable && !isEmpty">
        <gc-sub-title
          icon="icon-bumenliebiao"
          :text="'部门层级结构列表'"
          style="margin: 0 25px 0 23px"
        ></gc-sub-title>
        <!--  -->
        <el-tree
          ref="departmentRef"
          :data="treeData"
          default-expand-all
          :highlight-current="true"
          node-key="id"
          :props="defaultProps"
          :expand-on-click-node="false"
          :current-node-key="defaultCheckedId"
          @node-click="departmentNodeClick"
        >
          <template slot-scope="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-tree>
        <el-button
          @click="addDepartment"
          size="larget"
          type="primary"
          v-has="['utos:tosm:staff:org:insert']"
          >新增部门</el-button
        >
      </div>
      <div class="page-main-right">
        <gc-empty
          v-if="isEmpty"
          :page-type="orgEnable ? 'staffManageForDepartment' : 'staffManage'"
          @on-btn-click="addDepartment('', '')"
        ></gc-empty>
        <template v-else>
          <gc-sub-title
            icon="icon-bumen"
            :text="orgEnable ? checkedOrg.name : '员工列表'"
          >
            <template v-if="orgEnable">
              <el-button
                plain
                round
                active
                @click="handleEditApartment"
                v-has="'utos:tosm:staff:org:update'"
                >编辑部门信息</el-button
              >
              <el-button
                type="warning"
                round
                plain
                @click="deleteDepartment"
                v-has="'utos:tosm:staff:org:delete'"
                >删除</el-button
              >
            </template>
          </gc-sub-title>
          <template v-if="orgEnable">
            <div class="pale-title">基础信息</div>
            <department-info :info="checkedOrg"></department-info>
            <div class="pale-title" style="margin-bottom: 18px">员工列表</div>
          </template>
          <div v-else style="margin-top: 20px"></div>
          <gc-custom-search
            :search.sync="staffSearch.value"
            key-word-width="72px"
            key-word="员工姓名"
            placeholder="请输入检索关键字"
            @on-keyup-enter="getStaffList"
          >
            <i
              class="el-icon-search"
              slot="icon"
              style="cursor: pointer"
              @click="getStaffList"
            ></i>
          </gc-custom-search>
          <div class="staff-list">
            <staff-item
              v-for="item in staffList"
              :key="item.id"
              :staff-item="item"
              @modify-staff="modifyStaff"
              @update-account="modifyAccount"
            ></staff-item>
          </div>
        </template>
      </div>
    </div>

    <!-- 新增/编辑员工信息 -->
    <modify-staff
      :departmentTree="treeData"
      ref="updateStaffModel"
      @refresh-staff-list="getStaffList"
      @refresh="getOrganizeTree"
    />

    <!-- 新增/编辑部门 -->
    <modify-department
      v-if="orgEnable"
      ref="updateDepart"
      :tree-data="treeData"
      @refresh="getOrganizeTree"
    ></modify-department>
    <modify-account
      ref="modifyAccount"
      @refresh-staff-list="getStaffList"
    ></modify-account>
  </div>
</template>

<script>
import StaffItem from "./components/StaffItem.vue";
import DepartmentInfo from "./components/DepartmentInfo.vue";
import ModifyStaff from "./components/ModifyStaff.vue";
import ModifyDepartment from "./components/ModifyDepartment.vue";
import ModifyAccount from "./components/ModifyAccount.vue";

import {
  apiGetOrganizations,
  apiGetStaffList,
  apiDeleteOrg,
} from "@/api/organizeStaff";

export default {
  name: "StaffManage",
  components: {
    StaffItem,
    DepartmentInfo,
    ModifyStaff,
    ModifyDepartment,
    ModifyAccount,
  },
  props: {},
  data() {
    const searchTypeOptions = [
      {
        value: "name",
        label: "员工姓名",
      },
      {
        value: "staffNo",
        label: "员工号",
      },
    ];
    return {
      orgTree: [],
      defaultProps: {
        // eslint-disable-next-line no-unused-vars
        label(data, node) {
          const {
            tenant_organization: { name },
          } = data;
          return name;
        },
      },
      staffList: [],
      searchTypeOptions,
      staffSearch: {
        keyword: "name",
        value: "",
      },
      treeData: [],
      checkedOrg: {},
    };
  },
  computed: {
    defaultCheckedId() {
      return this.checkedOrg.id || "";
    },
    isEmpty() {
      const { value } = this.staffSearch;
      const isStaffSearchEmpty = value != 0 && !value;
      return this.orgEnable
        ? this.treeData.length === 0
        : isStaffSearchEmpty && this.staffList.length === 0;
    },
    rightOperateVisible() {
      // 权限待完善
      return true;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    orgEnable() {
      return this.userInfo.isOrgEnable || false;
    },
  },
  watch: {},
  activated() {
    this.getOrganizeTree();
  },
  methods: {
    modifyAccount(val) {
      this.$refs.modifyAccount.edit(val);
    },
    addDepartment() {
      this.$refs.updateDepart.add();
    },
    // 1、获取组织结构树 then 获取头层级的员工列表
    getOrganizeTree() {
      if (this.orgEnable) {
        apiGetOrganizations().then((res) => {
          if (!res) return (this.treeData = []);
          this.treeData = res.children;
          const firstChild = res.children[0];
          const { children, ...rest } = firstChild;
          this.checkedOrg = rest;
          this.getStaffList(this.checkedOrg.id);
        });
      } else {
        this.getStaffList();
      }
    },
    getStaffList() {
      apiGetStaffList({
        isPaged: false,
        orgId: this.checkedOrg.id,
        name: this.staffSearch.value, // 待办： 支持员工姓名、员工号查询
      }).then(({ dataList }) => {
        this.staffList = dataList;
      });
    },
    modifyStaff({ action, data }) {
      if (this.orgEnable && this.treeData.length === 0) {
        return this.$message.warning("请先创建部门");
      }
      this.$refs.updateStaffModel[action](data);
    },
    departmentNodeClick(data) {
      this.checkedOrg = data;
      this.getStaffList(data.id);
    },
    handleEditApartment() {
      this.$refs.updateDepart.edit(this.checkedOrg);
    },
    deleteDepartment() {
      this.$confirm("确定要删除该部门吗？删除后不可恢复。").then(() => {
        apiDeleteOrg(this.checkedOrg.id).then(() => {
          this.$message.success("删除成功");
          // 删除成功后刷新页面
          this.getOrganizeTree();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-main-left {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  ::v-deep {
    .el-button {
      margin: 20px;
    }
  }
}

::v-deep .el-tree {
  flex: 1;
  margin-top: 40px;
  .custom_tree {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .left_text {
      display: flex;
      align-items: center;
      p {
        color: #666666;
        font-size: 14px;
      }
      img {
        margin-left: 2px;
        position: relative;
        top: -1px;
        width: 12px;
      }
      &._disabled {
        cursor: not-allowed;
        color: #c9c9c9;
        // p {
        //   color: #c9c9c9;
        // }
      }
    }
  }
  .is-current {
    & > .el-tree-node__content {
      & > .el-tree-node__label {
        color: #2080f7;
      }
    }
  }
  .el-tree-node__content {
    line-height: 34px;
    height: 34px;
    padding-left: 17px;
    .el-checkbox__inner {
      border-radius: 0;
    }
    .el-tree-node__expand-icon {
      &:not(.is-leaf) {
        color: #666;
      }
    }
    &:hover {
      .el-icon-more {
        display: block !important;
        color: #2f87fe;
      }
    }
  }
}

.page-main-right {
  display: flex;
  flex-direction: column;
  .pale-title {
    margin-top: 40px;
    font-size: 14px;
    color: #ababab;
    line-height: 21px;
  }
  .staff-list {
    margin-top: 30px;
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
</style>
